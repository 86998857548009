// Import libs
import React from "react";

// Import components
import Seo from "../../components/Seo";
import Layout from "../../components/Layout";
import {
	Button,
	Cover
} from "@windish/shared";

// Import page-specific CSS
import "./index.css"

// Page constants
const pageTitle = "Error 404";
const pageDescription = "Error page for missing content";

// Page
export default function Error404() {

	function goHome() {
		window.location.href = '/';
	}

	// Render page
	return (
		<Layout pageTitle={pageTitle}>
			<Cover
				heading="Hm, that's awkward"
				subheading="There seems to be nothing here. Sorry for the inconvenience. Please check the URL, or visit the homepage and start again from there."
			>
				<Button type="cta" label="Go to the homepage" action={goHome} />
			</Cover>
		</Layout>
	);

}

// Page metadata
export const Head = () => (
	<Seo
		title={pageTitle}
		description={pageDescription}
	>
		<script type="application/ld+json">
			{`
        {
          "@context": "http://schema.org",
          "@type": "WebPage",
          "name": "${pageTitle}",
          "description": "${pageDescription}",
          "url": "https://vindis.io/404",
          "copyrightHolder": "Aljaž Vindiš",
          "copyrightYear": 2022,
					"author": {
						"@type": "Person",
						"name": "Aljaž Vindiš",
						"url": "https://vindis.io/"
					},
					"publisher": {
						"@type": "Person",
						"name": "Aljaž Vindiš",
						"url": "https://vindis.io/"
					}
        }
      `}
		</script>
	</Seo>
)
